// components/Navbar.js
import React, { useState, useEffect } from 'react';
import logo from '../assets/KOTHIcon1.png';

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={logo} alt="Logo" />
      </div>
      {isMobile ? (
        <div className="hamburger" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          ☰
        </div>
      ) : (
        <ul className="navbar-menu">
          <li><a href="#hero">HOME</a></li>
          <li><a href="#how-it-works">HOW IT WORKS</a></li>
          <li><a href="#tokenomics">TOKENOMICS</a></li>
          <li><a href="https://t.me/solkingofthehill/" target="_blank" rel="noreferrer">COMMUNITY</a></li>
          <li><a href="https://www.dexscreener.com/" target="_blank" rel="noreferrer">CHART</a></li>
        </ul>
      )}
      {isMobile && isMenuOpen && (
        <ul className="navbar-menu mobile">
          <li><a href="#hero" onClick={() => setIsMenuOpen(false)}>HOME</a></li>
          <li><a href="#how-it-works" onClick={() => setIsMenuOpen(false)}>HOW IT WORKS</a></li>
          <li><a href="#tokenomics" onClick={() => setIsMenuOpen(false)}>TOKENOMICS</a></li>
          <li><a href="https://t.me/solkingofthehill/" target="_blank" rel="noreferrer">COMMUNITY</a></li>
          <li><a href="https://www.dexscreener.com/" target="_blank" rel="noreferrer">CHART</a></li>
        </ul>
      )}
    </nav>
  );
}

export default Navbar;