// components/HeroSection.js
import React, { useState, useEffect, useRef } from 'react';
import landscapeVideo from '../assets/Desktop.mp4';
import portraitGif from '../assets/Mobile.gif';

function HeroSection() {
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
  const videoRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsPortrait(window.innerHeight > window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    const playVideo = async () => {
      if (videoRef.current && !isPortrait) {
        try {
          await videoRef.current.play();
        } catch (err) {
          console.log("Autoplay was prevented:", err);
        }
      }
    };

    playVideo();

    return () => window.removeEventListener('resize', handleResize);
  }, [isPortrait]);

  return (
    <section id="hero" className="hero-section">
      {isPortrait ? (
        <img src={portraitGif} alt="Hero Animation" className="hero-gif" />
      ) : (
        <video 
          ref={videoRef}
          autoPlay 
          loop 
          muted 
          playsInline 
          className="hero-video"
        >
          <source src={landscapeVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
      <div className="hero-content">
        <p className="hero-text">One-liner description for King of the Hill</p>
        <button className="btn btn-secondary">BUY NOW</button>
        <button className="btn btn-secondary">COMMUNITY</button>
      </div>
    </section>
  );
}

export default HeroSection;