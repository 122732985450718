// components/Tokenomics.js
import React from 'react';

function Tokenomics() {
  return (
    <section id="tokenomics" className="tokenomics">
      <h2>TOKENOMICS</h2>
      <div className="tokenomics-grid">
        <div className="tokenomics-item">
          <h3>SUPPLY</h3>
          <p>10 Million $KING</p>
        </div>
        <div className="tokenomics-item">
          <h3>TAXES</h3>
          <p>2% on buys/2% on sells</p>
        </div>
        <div className="tokenomics-item">
          <h3>LIQUIDITY</h3>
          <p>Locked</p>
        </div>
      </div>
    </section>
  );
}

export default Tokenomics;