// App.js
import React from 'react';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import HowItWorks from './components/HowItWorks';
import Tokenomics from './components/Tokenomics';
import './fonts/TitleFont.ttf';
import './fonts/BodyFont.ttf';
import './styles.css';

function App() {
  return (
    <div className="App">
      <Navbar />
      <HeroSection />
      <HowItWorks />
      <Tokenomics />
    </div>
  );
}

export default App;